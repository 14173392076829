.name_input_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px 0;
  flex-grow: 1;
}

.name_input_form {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
}

.username_input {
  border-radius: 30px;
  border: 3px solid #000;
  display: flex;
  width: 100%;
  padding: 30px 0;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-family: NeuePowerTrial-Ultra;
  text-align: center;
}

@media only screen and (max-width: 640px) {
  .name_input_wrap {
    flex-grow: 0;
    justify-content: space-between;
  }

  .name_input_form {
    width: 100%;
    gap: 30px;
  }

  .username_input {
    font-size: 20px;
    padding: 24px 0;
    border-radius: 24px;
  }
}