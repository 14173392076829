.pannel {
  width: 80%;
  max-width: 1280px;
  height: fit-content;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: 4px solid #000;
  background: #FFF;
  color: #000;
  text-align: center;
  padding: 40px;
  box-shadow: -20px 20px #000;
}

@media only screen and (max-width: 640px) {
  .pannel {
    height: fit-content;
    width: 75%;
    max-width: 75%;
    min-height: 60vh;
    border: 3px solid #000;
    box-shadow: -8px 8px #000;
    padding: 24px;
  }
}