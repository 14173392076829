/* page container  */
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: fit-content;
  width: 100%;
}

.background_video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -99;
  pointer-events: none;
}

/* header */
.header {
  align-items: center;
  padding: 24px 0;
  display: flex;
  width: 90%;
  justify-content: space-between;
}

.header img {
  width: 24px;
  height: 24px;
}

.header span {
  font-size: 24px;
}

/* content */
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
  width: 100%;
  max-width: 1280px;
  padding: 24px 0;
}

/* footer */
.footer {
  align-items: center;
  padding: 24px 0;
  display: flex;
  width: 90%;
  font-size: 24px;
  justify-content: space-between;
}

.footer a {
  text-decoration: none;
  color: rgba(255, 255, 255, .5);
}

.footer a:hover {
  color: rgba(255, 255, 255, 1);
}

@media only screen and (max-width: 640px) {
  .footer {
    font-size: 16px;
  }

  .header span {
    font-size: 18px;
  }
}