.welcome_screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;
  flex-grow: 1;
}

.welcome_custom_pannel {
  padding: 24px 0;
}

.welcome_screen h1 {
  margin: auto 0;
  width: 80%;
}

.start_playing {
  width: fit-content !important;
  height: 100px !important;
  border-radius: 30px !important;
}

@media only screen and (max-width: 640px) {
  .start_playing {
    width: 100% !important;
    height: 74px !important;
    border-radius: 24px !important;
  }

  .welcome_screen h1 {
    width: 100%;
  }
}