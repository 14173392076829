@font-face {
  font-family: NeuePowerTrial-Ultra;
  src: url('./fonts/NeuePowerTrial-Ultra.ttf');
}

@font-face {
  font-family: MonumentExtended-Bold;
  src: url('./fonts/MonumentExtended-Bold.woff');
}

@font-face {
  font-family: Roobert-Semibold;
  src: url('./fonts/Roobert-Semibold.woff');
}

body {
  margin: 0;
  color: white;
  font-family: NeuePowerTrial-Ultra;
}

h1 {
  font-size: 90px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 30px;
}

p {
  font-size: 20px;
}

.purple_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 36px;
  font-size: 32px;
  color: #fff;
  background: #6918FF;
  border-radius: 100px;
  border: 0;
  text-align: center;
  cursor: pointer;
  width: 354px;
  font-family: NeuePowerTrial-Ultra;
}

.purple_button:disabled {
  background-color: #CDCDCD;
}

.black_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  border: 2px solid black;
  text-align: center;
  border-radius: 30px;
  min-height: 100px;
  font-size: 32px;
  padding: 0 12px;
  cursor: pointer;
  transition: all 0.25s ease;
  font-family: NeuePowerTrial-Ultra;
}

.black_button:disabled {
  border-color: #CDCDCD;
  background-color: #CDCDCD;
}

.black_button:hover:enabled {
  background-color: white;
  color: #6918FF;
  border: 2px solid #6918FF;
}

.black_button:focus:enabled {
  background-color: white;
  color: #6918FF;
  border: 2px solid #6918FF;
}

.black_button_selected {
  background-color: white;
  color: #6918FF;
  border: 2px solid #6918FF;
}

@media only screen and (max-width: 640px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 20px;
  }

  .black_button {
    font-size: 20px;
    min-height: 74px;
    height: fit-content;
    border-radius: 24px;
    padding: 12px;
  }

  .purple_button {
    font-size: 20px;
    height: 54px;
    min-width: 200px;
    width: fit-content;
  }
}