.preorder_screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  height: 100%;
  padding: 10px;
  position: relative;
  gap: 40px;
}

.preorder_screen h2 {
  font-size: 60px;
}

.ghost_button {
  border: none;
  font-family: NeuePowerTrial-Ultra;
  color: #6918FF;
  background: none;
  cursor: pointer;
  margin: 0;
}

.preorder_button {
  width: 70% !important;
  height: 100px !important;
  border-radius: 30px !important;
}

@media only screen and (max-width: 640px) {
  .preorder_screen {
    gap: 35px;
    width: 100%;
    margin: 24px 0;
  }

  .preorder_screen h2 {
    font-size: 26px;
  }

  .preorder_button {
    width: 100% !important;
    height: 74px !important;
    border-radius: 24px !important;
  }
}