.spinner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
}

.spinner_img {
  margin-bottom: 24px;
  width: 200px;
  height: 200px;
  animation: spinAndScale 1.5s linear infinite;
}

@keyframes spinAndScale {
  0% {
    transform: rotate(0) scale(1);
  }

  50% {
    transform: rotate(180deg) scale(0.6);
  }

  100% {
    transform: rotate(360deg) scale(1);
  }
}