.trait_card_screen {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.result_card_wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  position: relative;
}

.trait_card_border {
  border-radius: 50px;
  border: 2px solid #353535;
  overflow: hidden;
}

.trait_card {
  width: 393px;
  height: fit-content;
  min-height: 665px;
  background: white;
  color: black;
  position: relative;
}

.gradient {
  width: 100%;
  height: 100%;
  position: absolute;
}

.dots_texture {
  position: absolute;
  bottom: 0;
  width: 393px;
  height: 262px;
}

.public_trait_area {
  position: relative;
  height: 102px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.rebrand_text {
  font-size: 18px;
  font-family: MonumentExtended-Bold;
}

.stroke_backgroud {
  position: absolute;
  width: 323px;
  height: 104px;
}

.public_trait_text {
  position: relative;
  z-index: 2;
  margin: 0;
  width: 323px;
  max-width: 323px;
  font-size: 32px;
  font-family: MonumentExtended-Bold;
}

.traitdetails {
  position: relative;
  padding: 24px 16px;
  z-index: 50;
}

.doll_bubbles_area {
  position: relative;
  width: fit-content;
}

.doll_bubbles_area img {
  position: relative;
}

.doll_bubbles_area div {
  font-size: 20px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roobert-Semibold;
}

.superlative_bubble1 {
  top: 10px;
  left: 36.5px;
  width: 291px;
  height: 86px;
}

.superlative_bubble2 {
  width: 134px;
  height: 74px;
  top: 121px;
  left: 12px;
}

.superlative_bubble3 {
  width: 100px;
  height: 86px;
  top: 139px;
  right: 12px;
}

.save_button {
  background-color: black;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid black;
  text-align: center;
  border-radius: 30px;
  width: 90%;
  height: 100px;
  font-size: 32px;
  padding: 0 12px;
  cursor: pointer;
  margin-top: 24px;
  font-family: NeuePowerTrial-Ultra;
}

.traits_card_mobile_wrap {
  display: flex;
  justify-content: center;
  color: black;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 640px) {
  .save_button {
    width: fit-content;
    font-size: 14px;
    height: 52px;
    position: fixed;
    bottom: 12px;
    z-index: 99;
    left: 0;
    right: 0;
    margin: auto;
    font-family: MonumentExtended-Bold;
  }

  .result_card_wrap {
    justify-content: center;
    width: 65%;
  }

  .trait_card_img {
    width: 100%;
    height: auto;
    position: relative;
    margin: auto;
    z-index: 99;
  }

  .traitdetails {
    padding: 24px 0;
    margin: auto;
    height: fit-content;
  }

  .dots_texture {
    bottom: -20px;
  }

  .public_trait_text {
    font-size: 8.205vw;
  }
}