.question_pannel_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
}

.answers_wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;
}

.option {
  width: 80% !important;
}

.next_button {
  margin-top: 40px;
}

@media only screen and (max-width: 640px) {
  .next_button {
    width: 60%;
  }

  .option {
    width: 100% !important;
  }
}